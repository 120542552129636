import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Image } from '../../../Image';
import { getMaxWidthsBySize } from '../../../../domain/utils/getMaxWidthsBySize';
import { t } from '../../../../domain/services/configService';
import { facePaint } from '../../../../domain/theme/utils/facePaint';
import {
  ngartImg,
  ngartImgGallery,
  ngartImgGalleryControls,
  ngartImgGalleryItemImages,
  ngartImgGalleryOpenBtn,
  ngartImgGalleryPromo,
  ngartImgViewGallery
} from '../../../Styles/articleImage';
import { ParagraphWrapper } from '../../../Paragraph/ParagraphWrapper';
import { IconPlus } from '../../../Icon/IconPlus';
import { Modal } from '../Modal';
import { ExpandedGallery } from '../ExpandedGallery';

const styles = {
  contentVisibility: css`
    content-visibility: auto;
    ${facePaint({
      'contain-intrinsic-size': ['0 476px', '0 587px', '0 488px', '0 520px']
    })};
  `
};
const InlineGalleryInner = ({
  galleryId,
  imageEntities,
  content,
  isAboveTheFold
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const expandGallery = () => {
    setIsExpanded(true);
  };

  const collapseGallery = () => {
    setIsExpanded(false);
  };

  useEffect(() => {
    const url = new URL(document.location);
    if (galleryId === parseInt(url.searchParams.get('gallery'), 10)) {
      expandGallery();
    } else {
      collapseGallery();
    }
  }, []);

  if (
    typeof imageEntities === 'undefined' ||
    typeof imageEntities[0] === 'undefined'
  ) {
    return <div />;
  }

  return (
    <>
      <div
        className="ngart-img--gallery"
        css={[styles.contentVisibility, ngartImg, ngartImgGallery]}
        onClick={expandGallery}
        onKeyPress={expandGallery}
        role="button"
        tabIndex={0}
      >
        <div css={ngartImgGalleryItemImages}>
          <div css={ngartImgGalleryPromo}>
            {imageEntities[0]?.entity?.mediaImage && (
              <Image
                entity={imageEntities[0].entity}
                maxWidths={getMaxWidthsBySize('art_main_col_medium')}
                loading={isAboveTheFold ? 'eager' : 'lazy'}
              />
            )}
          </div>
        </div>
        <div css={ngartImgGalleryControls}>
          <span
            className="ngart-img--gallery__open-btn"
            css={ngartImgGalleryOpenBtn}
          >
            <IconPlus />
          </span>
          <span className="ngart-img__view-gallery" css={ngartImgViewGallery}>
            {t('view gallery')}
          </span>
        </div>
      </div>

      <Modal
        show={isExpanded}
        overlayColor="#000"
        closeCb={collapseGallery}
        closeText={t('close gallery')}
        closableBoxPositioningStyles={css`
          width: 100%;
        `}
        closeIconPosition={{ top: 0, right: 30 }}
      >
        <ExpandedGallery
          closeOverlay={collapseGallery}
          imageEntities={imageEntities}
          galleryId={galleryId}
          content={content}
        />
      </Modal>
    </>
  );
};
InlineGalleryInner.defaultProps = {
  isAboveTheFold: false
};

InlineGalleryInner.propTypes = {
  imageEntities: PropTypes.arrayOf(PropTypes.shape({ entity: PropTypes.any }))
    .isRequired,
  galleryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  isAboveTheFold: PropTypes.bool
};

export const InlineGallery = ParagraphWrapper(InlineGalleryInner);
